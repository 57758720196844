<template>
  <div>
    <Register></Register>
  </div>
</template>

<script>
// @ is an alias to /src
import Register from '@/components/Register.vue'

export default {
  name: 'Registerview',
  components: {
    Register,
  }
}
</script>
