<template>
  <section class="ftco-section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6 text-center mb-2">
          <!--<a class="navbar-brand m-auto" href="/"><img v-bind:src="require('@/assets/images/logoWhite2.png')" width="270"></a>-->
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-6 text-center mb-5">
          <div class="heading-section text-dark p-2">
            {{$t("useraut.slogan1")}}
          </div>
          <a :href="SITE_URL" class="urlclass">{{ SITE_URL }}</a>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-7 col-lg-5">
          <div class="login-wrap p-4 p-md-5">
            <div class="d-flex">
              <div class="w-100">
                <h3 class="mb-4 text-dark d-flex align-items-center justify-content-center">{{$t("useraut.signup")}}</h3>
              </div>
            </div>
            
            <form action="#" class="login-form" v-on:submit.prevent="register">
              <div class="form-group d-flex justify-content-center">
                <button
                  class="btn btn-primary border-0 w-100 my-4"
                  type="button"
                  @click="connectMetamast('Metamask')"
                >
                  <img
                    :src="require('@/assets/images/metamask.png')"
                    class="align-middle"
                    width="40"
                  />
                  {{ $t("dashboard.metamaskconnect") }}
                </button>
              </div>
              <span class="d-flex align-items-center justify-content-center mb-5 urlclass"> {{ $t("useraut.or") }} </span>
              <div class="form-group">
                <div class="icon d-flex align-items-center justify-content-center">
                  <span class="fa fa-envelope"></span>
                </div>
                <input type="email" :disabled="disabled == 1" class="form-control rounded-left" name="email"
                  v-model.lazy="email" :placeholder="$t('useraut.email')" autocomplete="nope" required />
                <small v-if="!$v.email.email" class="error form-text text-danger">{{$t("useraut.validaddress")}}
                </small>
              </div>
              <div class="form-group" data-kt-password-meter="true">
                <div class="position-relative mb-3">
                  <div class="icon d-flex align-items-center justify-content-center">
                    <span class="fa fa-lock"></span>
                  </div>
                  <input class="form-control rounded-left" :disabled="disabled == 1" type="password"
                    :placeholder="$t('useraut.password')" v-model.lazy="password" name="password" autocomplete="off" required />
                  <!--begin::Visibility toggle-->
                  <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                    data-kt-password-meter-control="visibility">
                    <i class="bi bi-eye-slash fs-2"></i>
                    <i class="bi bi-eye fs-2 d-none"></i>
                  </span>
                  <!--end::Visibility toggle-->
                </div>
                <div class="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
                  <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                  <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                  <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                  <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
                </div>
              </div>
              <div class="form-group">
                <div class="icon d-flex align-items-center justify-content-center">
                  <span class="fa fa-lock"></span>
                </div>
                <input type="password" :disabled="disabled == 1" class="form-control rounded-left" name="rpassword"
                  v-model="rpassword" :placeholder="$t('useraut.confirm')" required />
                <small v-if="!$v.rpassword.sameAs" class="form-text text-danger">{{$t("useraut.passnotsame")}}</small>
              </div>
              <div class="icon d-flex align-items-center justify-content-center">
                <vue-recaptcha @verify="onVerify" class="mb-3" @expired="onExpired"
                  sitekey="6LfpOt0dAAAAAMwVBKdOFgZ1Gsqnm1O6N87Dsorb" :loadRecaptchaScript="true"></vue-recaptcha>
              </div>
              <div class="form-group d-flex align-items-center">
                <div class="w-100">
                  <label class="checkbox-primary mb-0">
                    <input type="checkbox" checked disabled class="form-check-input widget-13-check" />
                    {{$t("useraut.slogan2")}}
                  </label>
                </div>

                <div v-if="buttonDisabled === 0" class="w-100 d-flex justify-content-end">
                  <button type="submit" class="btn btn-primary submit">
                    {{$t("nav.register")}}
                  </button>
                </div>
                <div v-if="buttonDisabled === 1" class="w-100 d-flex justify-content-end">
                  <button type="button" class="btn btn-primary submit ps-15 pe-15">
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
              <div class="form-group mt-4">
                <div class="w-100 text-center">
                  <p class="mb-1">
                    {{$t("useraut.haveaccount")}}
                    <a :href="`/${this.$session.get('lang')}/login`">{{$t("useraut.loginbtn")}} </a>
                  </p>
                </div>
              </div>
              <div class="form-group mt-4">
                <div class="w-100 text-center">
                  <language-switcher></language-switcher>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import DataService from "../services/DataService";
  import Encrypt from "../services/Encrypt";
  import { email, required, minLength, sameAs } from "vuelidate/lib/validators";
  import VueRecaptcha from "vue-recaptcha";
  import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
  import Web3 from "web3";
  export default {
    components: { "vue-recaptcha": VueRecaptcha, LanguageSwitcher },
    name: "Register",
    metaInfo: function() {
    return {
      title: this.$t("metatag.titledefault"),
      titleTemplate: "%s | "+this.$t("metatag.titleDetailHome"),
      meta: [
      { name: 'description', content:  this.$t("metatag.description") },
      { name: 'keywords', content:  this.$t("metatag.keywords") }
      ]
    }
  },
    data() {
      return {
        email: "",
        password: "",
        rpassword: "",
        recaptchaStatus: false,
        disabled: 0,
        term: "",
        browser: "",
        browserLang: "",
        platform: "",
        SITE_URL: process.env.VUE_APP_SITE_URL,
        buttonDisabled:0
      };
    },
    validations: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial
          );
        },
      },
      rpassword: {
        required,
        minLength: minLength(8),
        sameAs: sameAs("password"),
      },
    },
    methods: {
      sessionKontrol() {
        if (this.$session.get("logtoken") && this.$session.get("sessionTimeout")) {
          window.location.href = `/${this.$session.get("lang")}/dashboard`;
        }
      },
      userActivity() {
        DataService.userActivity().then((response) => {
          this.term = response.data.ip;
        });
        this.browser = navigator.userAgent;
        this.browserLang = navigator.language;
        this.platform = navigator.platform;
      },
      togglePassword() {
        var element = document.getElementById("password");
        element.type = element.type == "password" ? "text" : "password";
      },
      async createToken(
        data0 = "null",
        data1 = "null",
        data2 = "null",
        data3 = "null",
        data4 = "null",
        data5 = "null",
        data6 = "null",
        data7 = "null",
        data8 = "null",
        data9 = "null",
        data10 = "null"
      ) {
        var send = [];
        await DataService.veriSifrele([
          data0,
          data1,
          data2,
          data3,
          data4,
          data5,
          data6,
          data7,
          data8,
          data9,
          data10,
        ]).then((response) => {
          send.push(response.data);
        });
        return send;
      },
      async connectMetamast(walletApp) {
          const { ethereum } = window;
          if (ethereum) {
            window.web3 = new Web3(ethereum);
            try {
              await ethereum.request({ method: "eth_requestAccounts" });
              this.web3 = window.web3;
              this.checkAccounts(walletApp);
            } catch (error) {
              this.$vToastify.setSettings({
                position: "bottom-right",
                theme: "light",
              });
              this.$vToastify.warning("", error.message);
            }
          } else {
            this.web3 = null;
            console.log("NO_INSTALL_METAMASK");
            this.$vToastify.setSettings({
              position: "bottom-right",
              theme: "light",
            });
            this.$vToastify.warning(
              "",
              this.$t("dashboard.installmetamask"));
          }
        },
      async checkAccounts(walletApp) {
          if (this.web3 === null) return;
          this.web3.eth.getAccounts((err, accounts) => {
            if (err != null) {
              this.$vToastify.setSettings({
                position: "bottom-right",
                theme: "light",
              });
              this.$vToastify.warning("", err + "NETWORK_ERROR");
              return;
            }
            if (accounts.length === 0) {
              this.$vToastify.warning("", err + "NO_CONNECT_WALLET");
              return;
            }
            this.$vToastify.setSettings({
              position: "bottom-right",
              theme: "light",
              warningInfoDuration: 2000,
            });
            this.$vToastify.info(
              "",
              this.$t("dashboard.connectmetamask").replace("#degistir#",walletApp)
            );
            setTimeout(() => {
              DataService.loginUserMetamask({
              token:accounts[0]
            })
              .then((response) => {
                if (!response.data.error && response.data.status == "ok") {
                  this.$session.set("logtoken", response.data.logtoken);
                  this.$session.set("logID", response.data.userid);
                  this.$session.set("logMeta", true);
                  this.$vToastify.setSettings({
                    position: "bottom-right",
                    theme: "light",
                    successDuration: 2000,
                  });
                  this.$vToastify.success("",this.$t('useraut.logging'));
                  setTimeout(() => {
                    window.location.href = `/${this.$session.get("lang")}/dashboard`;
                  }, 1000);
                } else {
                  this.$vToastify.setSettings({
                    position: "bottom-right",
                    theme: "light",
                  });
                  this.$vToastify.warning(
                    "",
                    this.$t("message." + response.data.error)
                  );
                }
              })
              .catch((err) => {
                console.log(err);
              });
            }, 2000);
          });
        },
      async register() {
        if (!this.recaptchaStatus) {
          this.$vToastify.setSettings({
            position: "bottom-right",
            theme: "light",
          });
          this.$vToastify.warning(this.$t('useraut.recaptcha'));
          return true;
        }
        if (this.email != "" && this.password != "" && this.rpassword != "") {
          if(!this.$v.password.minLength){
            this.$vToastify.setSettings({position: "bottom-right",theme: "light",});
            this.$vToastify.warning(this.$t('useraut.strongpassword'));
            return;
          }
          if (this.$v.password.valid) {
            if (this.$v.$invalid === false) {
              this.buttonDisabled = 1;
              DataService.registerUser({
                token: await this.createToken(
                  this.email,
                  Encrypt.encrypt(this.password),
                  Encrypt.encrypt(this.rpassword),
                  this.$session.get("lang")
                ),
                activity: await this.createToken(
                  this.term,
                  this.browser,
                  this.browserLang,
                  this.platform
                ),
              }).then((response) => {
                if (!response.data.error && response.data.status == "ok") {
                  this.$vToastify.setSettings({
                    position: "bottom-right",
                    theme: "light",
                    successDuration: 10000,
                  });
                  this.$vToastify.success("",this.$t("message." + response.data.success));
                  this.disabled = 1;
                  setTimeout(() => {
                    this.$router.push(`/${this.$session.get("lang")}/login`);
                  }, 3000);
                } else {
                  this.buttonDisabled = 0;
                  this.password = "";
                  this.rpassword = "";
                  this.$vToastify.setSettings({
                    position: "bottom-right",
                    theme: "light",
                  });
                  this.$vToastify.warning("",this.$t("message." + response.data.error)
                  );
                }
              });
            } else {
              this.$vToastify.setSettings({
                position: "bottom-right",
                theme: "light",
              });
              this.$vToastify.warning(this.$t('useraut.errorform'));
            }
          } else {
            this.$vToastify.setSettings({position: "bottom-right",theme: "light",});
            this.$vToastify.warning(this.$t('useraut.strongpassword'));
          }
        } else {
          this.$vToastify.setSettings({
            position: "bottom-right",
            theme: "light",
          });
          this.$vToastify.warning(this.$t('useraut.blankfield'));
        }
      },
      onVerify(response) {
        this.recaptchaStatus = true;
        return response;
      },
      onExpired: function () {
        this.recaptchaStatus = false;
      },
      resetRecaptcha() {
        this.$refs.recaptcha.reset();
      },
      onSubmit: function () { },
    },
    mounted() {
      this.userActivity();
      this.sessionKontrol();
    },
  };
</script>
<style lang="" scoped src="../assets/css/login.css">
</style>